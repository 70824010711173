<template>
  <b-card>
    <div class="d-flex justify-content-between">
      <p class="card-title ">
        Advance Override
      </p>
      <b-link
        variant="outline-info mx-2"
        type="button"
        @click="onActivityLog"
      >
        Activity log
      </b-link>
    </div>

    <div class="row justify-content-center">
      <div class="col-12">
        <b-form
          @submit.prevent="handleAdvanceOverrideFormSubmit"
        >
          <b-row>
            <!-- AdvanceOverride Name -->
            <b-col cols="4">
              <b-form-group
                :state="coaState"
                invalid-feedback="Chart of Accounts is Required"
              >
                <v-select
                  v-model="advanceOverideFormData.coaId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="gl_name"
                  :options="coaData"
                  :disabled="!coaData.length"
                  :reduce="(val) => val.id"
                  placeholder="Select Chart of accounts"
                />
              </b-form-group>
            </b-col>
            <b-col cols="5">
              <b-form-group>
                <b-button
                  variant="outline-primary"
                  type="submit"
                  :disabled="isSubmitting || !advanceOverideFormData.coaId"
                >
                  {{ updateId ? "Update" : "Add" }}
                </b-button>
                <b-button
                  variant="outline-danger mx-2"
                  type="button"
                  :disabled="isSubmitting"
                  @click="onClearForm"
                >
                  Clear
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>
    <div
      v-if="!isLoading"
      class="row justify-content-center"
    >
      <table class="table table-striped">
        <thead class="thead-light">
          <tr>
            <th
              scope="col"
              class="text-left"
              :style="{width:'5%'}"
            >
              SL
            </th>
            <th scope="col">
              Name
            </th>
            <th
              scope="col"
              class="text-center"
            >
              Action
            </th>
          </tr>
        </thead>
        <draggable
          v-model="rows"
          tag="tbody"
        >
          <tr
            v-for="item in rows"
            :key="item.id"
          >
            <td
              scope="row"
              class="text-left"
            >
              {{ item.priority }}
            </td>
            <td>
              <align-justify-icon
                size="1.5x"
                class="custom-class"
              />
              {{ item.gl_name }}
            </td>
            <td class="text-center">
              <!-- Column: Action -->
              <span>
                <b-button
                  class="mr-1 px-1"
                  variant="outline-warning"
                  @click="editRow(item)"
                >Edit</b-button>
                <b-button
                  class="px-1"
                  variant="outline-danger"
                  @click="deleteRow(item)"
                >Delete</b-button>
              </span>

            </td>
          </tr>
        </draggable>
      </table>
      <b-button
        v-if="JSON.stringify(rows)!=JSON.stringify(customPriority)"
        :disabled="JSON.stringify(rows)===JSON.stringify(customPriority)"
        class="mt-3 "
        variant="outline-success"
        @click="onSavePriority"
      >
        Save Priority
      </b-button>
      <b-button
        v-if="JSON.stringify(rows)!=JSON.stringify(customPriority)"
        :disabled="JSON.stringify(rows)===JSON.stringify(customPriority)"
        class="mx-3 mt-3 "
        variant="outline-danger"
        @click="onPriorityReset"
      >
        Reset Priority
      </b-button>
    </div>
    <activity-modal
      v-if="activityModal"
      ref="activityModal"
      :tag="tag"
    />
    <preloader v-if="isLoading" />
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BForm, BButton, BLink, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import FemsToastrService from '@/@service/utils/FemsToastr.service'
import AccAdvanceOverrideService from '@/@service/api/accounting/AccAdvanceOverrides.service'
import draggable from 'vuedraggable'
import { AlignJustifyIcon } from 'vue-feather-icons'
import Preloader from '../components/preloader/preloader.vue'
// import ActivityTable from '../audit/components/ActivityTable.vue'
import ActivityModal from '../audit/ActivityModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BCard,
    BButton,
    BLink,
    vSelect,
    Preloader,
    draggable,
    AlignJustifyIcon,
    // ActivityTable,
    ActivityModal,
  },

  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      updateId: null,
      isLoading: false,
      isLoadingAudit: false,
      isSubmitting: false,
      rows: [],
      advanceOverideFormData: {
        coaId: '',
      },
      coaData: [],
      coaState: null,
      categoryState: null,
      statusState: null,
      customPriority: [],
      tag: 'acc-advance-override',
      activityModal: false,

    }
  },
  computed: {
  },
  created() {
    this.getChartOfAccounts()
    this.getGridData()
  },
  methods: {
    getChartOfAccounts() {
      AccAdvanceOverrideService.getChartOfAccounts().then(
        response => {
          const res = response.data
          this.coaData = res.data
        },
      )
    },
    handleAdvanceOverrideFormSubmit() {
      if (this.updateId) {
        this.onUpdate()
      } else {
        this.onCreate()
      }
    },

    validate() {
      if (!this.advanceOverideFormData.coaId) {
        this.coaState = false
      } else {
        this.coaState = null
        return true
      }
      return false
    },
    onCreate() {
      if (!this.validate()) {
        return
      }
      this.isSubmitting = true
      const params = {
        coaId: this.advanceOverideFormData.coaId,
      }
      AccAdvanceOverrideService.create(params).then(
        response => {
          const res = response.data
          this.isSubmitting = false
          if (res.status) {
            this.getGridData()
            this.resetAdvanceOverride()
            FemsToastrService.success(res.message)
          } else {
            FemsToastrService.error(res.message)
          }
        },
      ).catch(
        error => {
          this.isSubmitting = false
          FemsToastrService.error(error.message)
        },
      )
    },
    onUpdate() {
      if (!this.validate()) {
        return
      }
      this.isSubmitting = true
      const params = {
        coaId: this.advanceOverideFormData.coaId,
      }
      AccAdvanceOverrideService.update(params, this.updateId).then(
        response => {
          const res = response.data
          if (res.status) {
            this.isSubmitting = false
            this.getGridData()
            this.resetAdvanceOverride()
            FemsToastrService.success(res.message)
          } else {
            this.isSubmitting = false
            FemsToastrService.error(res.message)
          }
        },

      ).catch(
        error => {
          this.isSubmitting = false
          FemsToastrService.error(error.message)
        },
      )
    },
    onSavePriority() {
      if (JSON.stringify(this.rows) === JSON.stringify(this.customPriority)) {
        FemsToastrService.warning('Priority no changes')
        return
      }
      const priorityData = []
      this.rows.forEach((item, index) => {
        priorityData.push({
          id: item.id,
          priority: index + 1,
        })
      })
      const params = {
        priorities: JSON.stringify(priorityData),
      }
      this.isLoading = true
      AccAdvanceOverrideService.priorityUpdate(params).then(
        response => {
          const res = response.data
          this.isLoading = false
          if (res.status) {
            this.getGridData()
            this.resetAdvanceOverride()
            FemsToastrService.success(res.message)
          } else {
            FemsToastrService.error(res.message)
          }
        },
      ).catch(
        error => {
          this.isLoading = false
          FemsToastrService.error(error.message)
        },
      )
    },
    onPriorityReset() {
      this.rows = this.customPriority
    },
    getGridData() {
      this.isLoading = true
      AccAdvanceOverrideService.getAll().then(
        res => {
          const response = res.data
          this.isLoading = false
          this.rows = response.data
          this.customPriority = response.data
        },
      ).catch(

        error => {
          this.isLoading = false
          console.log(error)
        },
      )
    },
    refreshGrid() {
      this.getGridData()
    },
    resetAdvanceOverride() {
      this.updateId = null
      this.advanceOverideFormData = {}
      this.coaState = null
      this.categoryState = null
      this.statusState = null
    },
    editRow(rowData) {
      this.updateId = rowData.id
      this.advanceOverideFormData = {
        coaId: rowData.coa_id,
      }
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    },
    deleteRow(rowData) {
      this.$swal({
        title: 'Are you sure to delete?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true

          AccAdvanceOverrideService.delete(rowData.id).then(
            response => {
              const res = response.data
              if (res.status) {
                this.resetAdvanceOverride()
                this.getGridData()
                FemsToastrService.success(res.message)
              } else {
                this.isLoading = false
                FemsToastrService.error(res.message)
              }
            },

          ).catch(
            error => {
              this.isLoading = false

              FemsToastrService.error(error.message)
            },
          )
        }
      })
    },
    onClearForm() {
      this.updateId = null
      this.coaState = null
      this.advanceOverideFormData = {}
    },
    onActivityLog() {
      this.activityModal = true
      this.$nextTick(() => {
        this.$refs.activityModal.modalShow()
      })
    },
  },
}
</script>
<style lang="scss">
svg.feather.feather-refresh-cw {
  height: 15px;
  width: 15px;
}
</style>
