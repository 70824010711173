import axiosIns from '@/libs/axios'

export default {
  getAll: (() => axiosIns.get('accounting/acc-advance-overrides')),
  create: (payload => axiosIns.post('accounting/acc-advance-overrides', payload)),
  getOne: (id => axiosIns.get(`accounting/acc-advance-overrides/${id}`)),
  update: ((payload, id) => axiosIns.put(`accounting/acc-advance-overrides/${id}`, payload)),
  delete: (id => axiosIns.delete(`accounting/acc-advance-overrides/${id}`)),
  getChartOfAccounts: (() => axiosIns.get('accounting/coa-mapping/std-fee-coa')),
  priorityUpdate: (payload => axiosIns.post('accounting/acc-advance-overrides/custom-priority', payload)),
}
